@import "animations.css";
@import "/node_modules/flag-icons/css/flag-icons.min.css";

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.container {
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  @apply px-4;
}
.iframe .container {
  @apply px-0;
}

.iframe .event-header {
  @apply mt-0;
}

body.dark {
  background-color: #000;
}

body.iframe:not(.popup) {
  @apply bg-transparent;
}

.iframe .organisation-info-btn {
  display: none;
}

.bg-gradient-black {
  background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(239,239,239,0) 100%);
}

.event-description a {
  text-decoration: underline;
}
.justify-ticket {
  @apply justify-between;
}
.xsgrid {
  @apply space-y-2;
}
@media(min-width: 640px) {
  .xsgrid {
    @apply grid space-y-0;
  }
  .justify-ticket {
    @apply justify-end;
    text-align: right;
  }
  .minw75 {
    min-width: 75px;
  }
}

.bck-to-org-btn .back-icon {
  opacity: 0;
  transform: translateX(15px);
  transition: all;
  transition-duration: 200ms;
}
.bck-to-org-btn .calendar-icon {
  opacity: 1;
  transform: translateX(0px);
  transition: all;
  transition-duration: 200ms;
}
.bck-to-org-btn:hover .calendar-icon {
  opacity: 0;
  transform: translateX(-15px);
}
.bck-to-org-btn:hover .back-icon {
  opacity: 1;
  transform: translateX(0px);
}

.react-date-picker__wrapper {
  border: 1px solid #d1d5db !important;
  padding: 5px !important;
  @apply rounded-md shadow-sm;
}

.max-w-2 {
  max-width: 24px;
}

.tips p {
  @apply bg-gray-100 p-2 px-3 text-gray-700 rounded-md text-xs dark:bg-gray-700 dark:text-gray-100;
}
.wallet-description a {
  @apply text-blue-600 underline;
}

body.popup {
  padding: 15px;
  padding-bottom: 80px;
}


#eventDatePicker {

  .flatpickr-calendar.inline, .flatpickr-rContainer, .flatpickr-day, .dayContainer, .flatpickr-days {
    width: 100% !important;
    max-width: 100% !important;
  }

  .flatpickr-input {
    display: none;
  }

  .flatpickr-calendar {
    border-radius: 5px !important;
    overflow: hidden !important;
    box-shadow: none;
  }

  button:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  .flatpickr-weekdays {
    height: 35px;
  }

    .flatpickr-weekday {
    font-size: 14px;

  }

  .flatpickr-day {
    height: 60px;
    font-size: 16px;
    display: flex;
    align-items: center;
    @media(max-width: 500px){
      height: 40px;
    }
  }
}

/*body.popup .event-header {*/
/*  position: fixed;*/
/*  width: 100%;*/
/*  top: 0;*/
/*  z-index: 99;*/
/*}*/
/*body.popup {*/
/*  padding-top: 100px;*/
/*}*/

/*body.popup .sub-footer {*/
/*  padding-bottom: 5px;*/
/*}*/

/*body.popup .event-title {*/
/*  white-space: nowrap;*/
/*  text-overflow: ellipsis;*/
/*  overflow: hidden;*/
/*}*/
