.fadeIn {
  -webkit-animation: fadein 300ms; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 300ms; /* Firefox < 16 */
  -o-animation: fadein 300ms; /* Opera < 12.1 */
  animation: fadein 300ms;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.slideUp {
  -webkit-animation: slideUp 300ms; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: slideUp 300ms; /* Firefox < 16 */
  -o-animation: slideUp 300ms; /* Opera < 12.1 */
  animation: slideUp 300ms;
}


@keyframes slideUp {
  from { transform: translateY(25px); }
  to   { transform: translateY(0); }
}

/* Firefox < 16 */
@-moz-keyframes slideUp {
  from { transform: translateY(25px); }
  to   { transform: translateY(0); }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes slideUp {
  from { transform: translateY(25px); }
  to   { transform: translateY(0); }
}

/* Internet Explorer */
@-ms-keyframes slideUp {
  from { transform: translateY(25px); }
  to   { transform: translateY(0); }
}

/* Opera < 12.1 */
@-o-keyframes slideUp {
  from { transform: translateY(25px); }
  to   { transform: translateY(0); }
}
